<template>
    <figure class="figure">
        <slot>
            <img
                loading="lazy"
                :src="src"
                :srcset="srcset"
                :sizes="sizes ? sizes : '100vw'"
                v-bind="$attrs"
            >
        </slot>

        <figcaption v-if="$slots.caption">
            <slot name="caption" />
        </figcaption>
    </figure>
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            required: false,
            default: null
        },
        srcset: {
            type: String,
            required: false,
            default: null
        },
        sizes: {
            type: String,
            required: false,
            default: null
        },
        alt: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style lang="less">
.figure {
    line-height: 0;

    img {
        width: 100%;
        object-fit: contain;
    }
}
</style>